export enum RoleEnum {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SALES = "SALES",
  OFFICE = "OFFICE",
  PRODUCTION = "PRODUCTION",
  READONLY = "READONLY",
  EMPTY = "EMPTY",
  TEST = "TEST",
}

export enum PermissionEnum {
  canRead = "canRead",
  canWrite = "canWrite",
  canDelete = "canDelete",
}

export enum ResourceEnum {
  TenantInfo = "TenantInfo", // テナント基本情報
  TenantGroup = "TenantGroup", // テナント事業所
  TenantMember = "TenantMember", // テナントメンバー
  TenantIntegration = "TenantIntegration", // テナント連携情報
  GroupInfo = "GroupInfo", // 事業所基本情報
  GroupPackPhase = "GroupPackPhase", // 事業所梱包フェーズ
  GroupReport = "GroupReport", // 案件横断帳票
  MasterReport = "MasterReport", // マスター案件帳票
  MasterData = "MasterData", // マスタ-データ
  GroupMember = "GroupMember", // 事業所メンバー
  Project = "Project", // 案件
  ProjectPhoto = "ProjectPhoto", // 案件写真
  ProjectFile = "ProjectFile", // 案件ファイル
  ProjectSchedule = "ProjectSchedule", // 案件スケジュール
  ProjectReport = "ProjectReport", // 案件帳票
  ProjectQuote = "ProjectQuote", // 案件見積
  ProjectProduct = "ProjectProduct", // 案件梱包
  ProjectInvoice = "ProjectInvoice", // 案件請求
  PriceOffset = "PriceOffset", // 単価補正
}

export interface IPermission {
  [PermissionEnum.canRead]: boolean;
  [PermissionEnum.canWrite]: boolean;
  [PermissionEnum.canDelete]: boolean;
}

export interface IPermissions {
  [ResourceEnum.TenantInfo]: IPermission;
  [ResourceEnum.TenantGroup]: IPermission;
  [ResourceEnum.TenantMember]: IPermission;
  [ResourceEnum.TenantIntegration]: IPermission;
  [ResourceEnum.GroupInfo]: IPermission;
  [ResourceEnum.GroupPackPhase]: IPermission;
  [ResourceEnum.GroupReport]: IPermission;
  [ResourceEnum.MasterReport]: IPermission;
  [ResourceEnum.MasterData]: IPermission;
  [ResourceEnum.GroupMember]: IPermission;
  [ResourceEnum.Project]: IPermission;
  [ResourceEnum.ProjectPhoto]: IPermission;
  [ResourceEnum.ProjectFile]: IPermission;
  [ResourceEnum.ProjectSchedule]: IPermission;
  [ResourceEnum.ProjectReport]: IPermission;
  [ResourceEnum.ProjectQuote]: IPermission;
  [ResourceEnum.ProjectProduct]: IPermission;
  [ResourceEnum.ProjectInvoice]: IPermission;
  [ResourceEnum.PriceOffset]: IPermission;
}

export interface IPermissionsState {
  [RoleEnum.ADMIN]: IPermissions;
  [RoleEnum.MANAGER]: IPermissions;
  [RoleEnum.SALES]: IPermissions;
  [RoleEnum.OFFICE]: IPermissions;
  [RoleEnum.PRODUCTION]: IPermissions;
  [RoleEnum.READONLY]: IPermissions;
  [RoleEnum.EMPTY]: IPermissions;
  [RoleEnum.TEST]: IPermissions;
}

// 権限設定
export const permissions: IPermissionsState = {
  [RoleEnum.ADMIN]: {
    [ResourceEnum.TenantInfo]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.TenantGroup]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.TenantMember]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.TenantIntegration]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupInfo]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupPackPhase]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.MasterReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.MasterData]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupMember]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.Project]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectPhoto]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectFile]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectSchedule]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectQuote]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectProduct]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectInvoice]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.PriceOffset]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
  },
  [RoleEnum.MANAGER]: {
    [ResourceEnum.TenantInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantGroup]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantIntegration]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupInfo]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupPackPhase]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.MasterReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.MasterData]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupMember]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.Project]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectPhoto]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectFile]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectSchedule]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectQuote]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectProduct]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectInvoice]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.PriceOffset]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
  },
  [RoleEnum.SALES]: {
    [ResourceEnum.TenantInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantGroup]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantIntegration]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupInfo]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupPackPhase]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterData]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.Project]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectPhoto]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectFile]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectSchedule]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectQuote]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectProduct]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectInvoice]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.PriceOffset]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
  },
  [RoleEnum.OFFICE]: {
    [ResourceEnum.TenantInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantGroup]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantIntegration]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupInfo]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupPackPhase]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterData]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.Project]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectPhoto]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectFile]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectSchedule]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectQuote]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectProduct]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectInvoice]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.PriceOffset]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
  },
  [RoleEnum.PRODUCTION]: {
    [ResourceEnum.TenantInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantGroup]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantIntegration]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupPackPhase]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupReport]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterReport]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterData]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.Project]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectPhoto]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectFile]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectSchedule]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectQuote]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectProduct]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true, //PAX-590 写真削除可能
    },
    [ResourceEnum.ProjectInvoice]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.PriceOffset]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
  },
  [RoleEnum.READONLY]: {
    [ResourceEnum.TenantInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantGroup]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantIntegration]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupPackPhase]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupReport]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterReport]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterData]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.Project]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectPhoto]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectFile]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectSchedule]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectQuote]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectProduct]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectInvoice]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.PriceOffset]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
  },
  [RoleEnum.EMPTY]: {
    [ResourceEnum.TenantInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantGroup]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.TenantIntegration]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupInfo]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupPackPhase]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupReport]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterReport]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.MasterData]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.GroupMember]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.Project]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectPhoto]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectFile]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectSchedule]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectReport]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectQuote]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectProduct]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.ProjectInvoice]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
    [ResourceEnum.PriceOffset]: {
      [PermissionEnum.canRead]: false,
      [PermissionEnum.canWrite]: false,
      [PermissionEnum.canDelete]: false,
    },
  },
  [RoleEnum.TEST]: {
    [ResourceEnum.TenantInfo]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.TenantGroup]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.TenantMember]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.TenantIntegration]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupInfo]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupPackPhase]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.MasterReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.MasterData]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.GroupMember]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.Project]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectPhoto]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectFile]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectSchedule]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectReport]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectQuote]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectProduct]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.ProjectInvoice]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
    [ResourceEnum.PriceOffset]: {
      [PermissionEnum.canRead]: true,
      [PermissionEnum.canWrite]: true,
      [PermissionEnum.canDelete]: true,
    },
  },
};
