import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import short from "short-uuid";

import Toast from "components/Toast";

type Severity = "error" | "warning" | "info" | "success";

interface AddAlertProps {
  message: string;
  severity: Severity;
  force?: boolean;
}

interface PaxAlert {
  id: string | short.SUUID;
  message: string;
  severity: Severity;
  force: boolean;
}

interface AlertsContextValue {
  alerts: PaxAlert[];
  addAlert: (newAlert: AddAlertProps) => string;
  removeAlert: (id: string) => void;
  setSnooze: (severities: Severity[]) => void;
}

const AlertsContext = createContext<AlertsContextValue>({
  alerts: [],
  addAlert: () => "",
  removeAlert: () => null,
  setSnooze: () => {},
});
export const AlertsProvider = ({ children }: { children: ReactNode }) => {
  const [alerts, setAlert] = useState<PaxAlert[]>([]);
  const [alertsBuffer, setAlertBuffer] = useState<PaxAlert[]>([]);
  const [snooze, setSnoozeState] = useState<Severity[]>([]);

  useEffect(() => {
    if (alertsBuffer.length > 0) {
      for (const alert of alertsBuffer) {
        if (alert.force || !snooze.includes(alert.severity))
          setAlert([...alerts, alert]);
        setAlertBuffer((prev) => prev.filter((a) => a.id !== alert.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snooze, alertsBuffer]);

  const setSnooze = (severities: Severity[]): void => {
    setSnoozeState(severities);
    return;
  };

  const addAlert = ({ message, severity, force }: AddAlertProps) => {
    const id = short().new();
    setAlertBuffer([
      ...alertsBuffer,
      { id, message, severity, force: force ?? false },
    ]);
    return id;
  };

  const removeAlert = (id: string) => {
    setAlert(alerts.filter((a) => a.id !== id));
  };
  return (
    <AlertsContext.Provider
      value={{ alerts, addAlert, removeAlert, setSnooze }}
    >
      {alerts.map((a) => (
        <Toast
          key={a.id}
          open={true}
          onClose={() => removeAlert(a.id)}
          message={a.message}
          severity={a.severity}
        />
      ))}
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlerts = () => {
  const alertsContext = useContext(AlertsContext);

  if (alertsContext === undefined) {
    throw new Error("useAlerts must be within AuthProvider");
  }

  return alertsContext;
};
