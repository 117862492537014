export const getAuthUser = /* GraphQL */ `
  query GetAuthUser($id: ID!) {
    getUser(id: $id) {
      id
      externalId
      name
      firstName
      lastName
      email
      showTutorial
      invited
      verified
      fontSize
      language
      receiveEmailNotification
      theme
      useMfa
      customId
      tenantId
      isAdmin
      createdAt
      updatedAt
      groups {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            tenantId
            createdAt
            updatedAt
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
          }
        }
        nextToken
      }
      tenant {
        disableMultiTenant
        groups {
          items {
            id
            name
          }
        }
      }
      tenants {
        items {
          id
          license
          userId
          tenantId
          isAdmin
          hidden
          name
          firstName
          lastName
          customId
          active
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            createdAt
            updatedAt
            groups {
              items {
                id
                name
                phone
                fax
                email
                logoUrl
                taxRate
                maxInventoryM3
                prefix
                fiscalYearStartMonth
                salesCutoffDay
                revenueRecognitionCriterion
                tenantId
                createdAt
                updatedAt
                mainSpeciesId
                mainSpeciesName
                mainSpeciesFaceMPa
                mainSpeciesEdgeMPa
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
