import { createContext, ReactNode, useMemo, useState, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface DialogProps {
  title: string;
  content: ReactNode | string;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

interface DialogContextValue {
  open: (props: DialogProps) => void;
  close: () => void;
}

const DialogContext = createContext<DialogContextValue>({
  open: () => null,
  close: () => null,
});

interface ProviderProps {
  children: ReactNode | ReactNode[];
}

export const DialogProvider = ({ children }: ProviderProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [_title, setTitle] = useState<string>();
  const [_content, setContent] = useState<ReactNode | string>();
  const [_okText, setOkText] = useState<string>("はい");
  const [_cancelText, setCancelText] = useState<string>("いいえ");
  const [_onOk, setOnOk] = useState<() => void>(() => null);
  const [_onCancel, setOnCancel] = useState<() => void>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const close = () => {
    setOpenDialog(false);
  };

  const handleOk = () => {
    close();
    !!_onOk && _onOk();
  };

  const handleCancel = () => {
    close();
    !!_onCancel && _onCancel();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const open = ({
    title,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
  }: DialogProps) => {
    setTitle(title);
    setContent(content);
    okText && setOkText(okText);
    cancelText && setCancelText(cancelText);
    !!onOk && setOnOk(() => onOk);
    !!onCancel && setOnCancel(() => onCancel);
    setOpenDialog(true);
  };

  const values = useMemo(() => ({ open, close }), [open, close]);

  return (
    <DialogContext.Provider value={values}>
      <Dialog
        open={openDialog}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{_title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {_content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{_cancelText}</Button>
          <Button onClick={handleOk} autoFocus>
            {_okText}
          </Button>
        </DialogActions>
      </Dialog>

      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const dialogContext = useContext(DialogContext);

  if (dialogContext === undefined) {
    throw new Error("useDialog must be within DialogProvider");
  }

  return dialogContext;
};
