import "utils/wdyr";
import "tailwindcss/tailwind.css";
import "global.css";
import "polyfills";
import type { AppProps } from "next/app";
import Amplify from "aws-amplify";
import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useRouter } from "next/router";

import Login from "layouts/Login";
import { AuthProvider, useAuth } from "contexts/auth";
import awsconfig from "aws-exports";
import ErrorFallback from "components/ErrorFallback";
import { startServiceWorker } from "utils/sw";
import { AlertsProvider, useAlerts } from "contexts/alerts";
import { useNetworkStatus } from "hooks/online";
import { DialogProvider } from "contexts/dialog";
import {
  ExternalShareInfoProvider,
  useExternalShareInfo,
} from "contexts/externalShareInfo";
import ExternalLogin from "layouts/ExternalLogin";

import "moment/locale/ja";

moment.locale("ja");

Amplify.configure(awsconfig);
// Amplify.Logger.LOG_LEVEL = "DEBUG";

const externalPath = "/external";

function PaxAppContent({ Component, pageProps }: AppProps) {
  const { user, loadingUser } = useAuth();
  const { addAlert } = useAlerts();
  const online = useNetworkStatus();
  const [prevOnlineStatus, setPrevOnlineStatus] = useState(online);
  const router = useRouter();

  useEffect(() => {
    startServiceWorker();
  }, []);

  useEffect(() => {
    if (online !== prevOnlineStatus) {
      if (online) {
        addAlert({
          message: "インターネット接続が復帰しました",
          severity: "info",
        });
      } else {
        addAlert({
          message: "インターネットに接続されていません",
          severity: "error",
        });
      }
      setPrevOnlineStatus(online);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  if (loadingUser) return <LinearProgress color="primary" />;

  // /_errorにアクセスしている場合はComponentを表示(エラーページ)
  if (
    user === undefined &&
    !loadingUser &&
    !router.pathname.endsWith("/_error")
  )
    return <Login />;

  return <Component {...pageProps} />;
}

function PaxExternalAppContent({ Component, pageProps }: AppProps) {
  const { isExternalLogin, loading } = useExternalShareInfo();
  const router = useRouter();
  const { projectId } = router.query;
  const { addAlert } = useAlerts();
  const online = useNetworkStatus();
  const [prevOnlineStatus, setPrevOnlineStatus] = useState(online);

  useEffect(() => {
    startServiceWorker();
  }, []);

  useEffect(() => {
    if (online !== prevOnlineStatus) {
      if (online) {
        addAlert({
          message: "インターネット接続が復帰しました",
          severity: "info",
        });
      } else {
        addAlert({
          message: "インターネットに接続されていません",
          severity: "error",
        });
      }
      setPrevOnlineStatus(online);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  if (loading) return <LinearProgress color="primary" />;
  if (!isExternalLogin)
    return <ExternalLogin projectId={projectId as string} />;

  return <Component {...pageProps} />;
}

function PaxApp(appProps: AppProps) {
  const router = useRouter();
  if (router.pathname.startsWith(externalPath)) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AlertsProvider>
          <ExternalShareInfoProvider>
            <DialogProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <PaxExternalAppContent {...appProps} />
              </LocalizationProvider>
            </DialogProvider>
          </ExternalShareInfoProvider>
        </AlertsProvider>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AlertsProvider>
        <AuthProvider>
          <DialogProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <PaxAppContent {...appProps} />
            </LocalizationProvider>
          </DialogProvider>
        </AuthProvider>
      </AlertsProvider>
    </ErrorBoundary>
  );
}

export default PaxApp;
