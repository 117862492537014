import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useAuth } from "contexts/auth";
import { useAlerts } from "contexts/alerts";

const theme = createTheme();

// https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#forgot-password

export default function NewPassword() {
  const { setNewPassword, setChallenge } = useAuth();
  const { addAlert } = useAlerts();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("password") !== data.get("repassword"))
      return addAlert({
        message: "パスワードが一致しません",
        severity: "error",
      });
    setNewPassword(data.get("password") as string);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh", width: "100vw" }}>
        <CssBaseline />
        <Box
          sx={{
            width: "100vw",
            mx: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            新しいパスワード
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="パスワード"
              name="password"
              type="password"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="repassword"
              label="パスワードの確認"
              type="password"
              id="repassword"
            />
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              sx={{ mt: 3, bgColor: "blue" }}
            >
              保存
            </Button>
            <Button
              type="button"
              fullWidth
              size="large"
              variant="text"
              sx={{ mt: 2, mb: 2 }}
              onClick={() => setChallenge(null)}
            >
              キャンセル
            </Button>
          </Box>
        </Box>
      </Grid>
    </ThemeProvider>
  );
}
